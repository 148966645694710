import React, { useState } from 'react';
import {
  TextField,
  Paper,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

const ImageForm = () => {
  const [formData, setFormData] = useState({
    questionImage: '',
    answerImage: '',
  });
  const [showQuestionImage, setShowQuestionImage] = useState(true);

  // Handle field changes
  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle image flipping
  const handleFlipImage = () => {
    setShowQuestionImage(!showQuestionImage);
  };

  // Function to get the final image URL based on the source string
  const getImageUrl = (imageSrc) => {
    if (imageSrc.startsWith('/IMAGES')) {
      return `${process.env.REACT_APP_S3_URL_BASE}${imageSrc}`;
    }
    return imageSrc;
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      {/* Input fields for question and answer images */}
      <TextField
        fullWidth
        label="Question Image URL"
        name="questionImage"
        value={formData.questionImage}
        onChange={handleFieldChange}
        variant="outlined"
        sx={{ marginTop: 1 }}
      />
      <TextField
        fullWidth
        label="Answer Image URL"
        name="answerImage"
        value={formData.answerImage}
        onChange={handleFieldChange}
        variant="outlined"
        sx={{ marginTop: 2 }}
      />

      {/* Display the image and flip button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <IconButton color="primary" onClick={handleFlipImage}>
          <FlipCameraAndroidIcon fontSize="large" />
        </IconButton>
      </Box>

      {/* Image display */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        {formData.questionImage || formData.answerImage ? (
          <img
            src={showQuestionImage ? getImageUrl(formData.questionImage) : getImageUrl(formData.answerImage)}
            alt="Question or Answer"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ) : (
          <Typography variant="body1">No image to display</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ImageForm;
